@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --yarl__color_backdrop: rgba(0, 0, 0, 0.9);

    --rt-color-white: #fff;
    --rt-color-dark: #FF6047;
    --rt-color-success: #8dc572;
    --rt-color-error: #be6464;
    --rt-color-warning: #f0ad4e;
    --rt-color-info: #337ab7;
    --rt-opacity: 0.9;
}

body {
    overflow-x: hidden;
}

.yarl__slide_captions_container {
    background: none;
}

.yarl__slide_title {
    text-align: center;
    padding-left: 38px;
}

// img.yarl__slide_image {
//     background-color: rgb(249 250 251);
//     background-color: #80B7BF;
// }